body {
  background-color: #E7E7E8;
}
.App {
  margin: 15px auto;
  max-width: 900px;
}
#header, #footer {
  display: grid;
  grid-gap: 40px;
  grid-template-columns: auto auto;
  padding: 20px;
}
#header p {
  margin-top: 22px;
  margin-left: 20px;
  color: #005980;
}
#main-content {
  margin-top: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 40px;
}
#main-content p{
  margin-top: 0;
  margin-bottom: 40px;
}
#form-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}
#footer p {
  font-size: 14px;
  margin-top: 0;
}
#footer img {
  margin-bottom: 10px;
}
.copyright {
  font-size: 12px;
}

label {
  color: #1B9538;
  font-weight: bold;
}

input {
  padding: 10px;
  font-size: 22px;
  width: calc(100% - 60px);
  margin-top: 5px;
  margin-bottom: 15px;
  background-color: #EDEDED;
  border: none;
  border-radius: 10px;
}
input::placeholder {
  color: #A3A3A3;
}
button:focus, input:focus{
  outline: none;
}

button {
  width: 125px;
  background-color: #77BC1F;
  color: white;
  padding: 8px 30px;
  font-size: 20px;
  border-radius: 10px;
  margin-top: 20px;
}
button:disabled {
  background-color: #bbbbbb;
}
h1, h3 {
  color: #1B9538;
  margin-top: 0;
}
h3 {
  font-size: 0.92em;
}
a {
  color: #1B9538;
}

.bill-img {
  box-shadow: 0 0 10px #aaa;
  border-radius: 10px;
  width: 400px;
}


/* --------------------------------------------------------------------
MEDIA QUERIES
---------------------------------------------------------------------*/

@media screen and (max-width: 750px) {
  #header, #footer {
    display: grid;
    grid-gap: 0px;
    grid-template-columns: auto;
    padding: 0px 40px;
  }
  #header img {
    margin-bottom: 10px;
    width: 100%;
  }
  #header img {
    margin-bottom: 0px;
  }
  #header p {
    margin: 20px 0px 0px 0px;
  }
  #form-container {
    display: block;
  }
  #form-container > form{
    width: 100%;
    margin-bottom: 30px;
  }
  #form-container > form > label > input[type="text"] {
    width:calc(100% - 20px);
  }
  #form-container > form > button {
    width: 100%;
    padding: 15px 30px;
  }
  #form-container > div > img {
    width: 100%;
  }
  h3 {
    margin-bottom: 30px;
  }
  #footer > p{
    font-size: 10px;
    margin: 10px 0px;
  }
}
@media screen and (max-width: 500px) {
  #header > p {
    display: none;
  }
  #main-content > p {
    display: none;
    padding: 0px 20px;
  }
}

@media screen and (max-width: 400px) {
  #header, #footer {
    padding: 0px 20px;
  }
  #main-content {
    padding: 30px;
  }

}
